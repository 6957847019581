@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Roboto Google Font */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* Import Alfa Slab One Google Font */
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");

body {
	font-family: "Roboto";
	overflow-x: hidden;
	@apply text-secondary;
}

p {
	@apply mb-4;
}

.animate {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.shadowTitleSecondary {
	text-shadow: 3px 3px #fee7ba;
}
